import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { GitHub, Blog, Work, YouTube } from "../icons"
import * as styles from "../../styles/styles.module.css"
import BaseWidget from "./basewidget"

const AuthorWidget = () => {
  return (
    <BaseWidget title="著者" hideTitle={true}>
      <div className={styles.authorProfile}>
        <StaticImage
          src="../../images/gotohayato.jpg"
          alt="後藤隼人"
          aspectRatio={1 / 1}
          width={110}
          className={styles.authorProfileImg}
        />
        <div className={styles.authorProfileText}>
          <p>
            <ruby>
              後藤隼人 <rp>(</rp>
              <rt>ごとうはやと</rt>
              <rp>)</rp>
            </ruby>
          </p>
          <p>
            ソフトウェア開発・サイト構築などをしています。
            お仕事のご相談・お問い合わせは
            <a href="https://contact.dyno.design">こちら</a>にください。
          </p>
          <p className={styles.authorProfileIcons}>
            <a
              href="https://github.com/gh640"
              rel="me"
              aria-label="GitHub"
              className={styles.authorProfileIcon}
            >
              <GitHub size="1.1em" />
            </a>
            <a
              href="https://gotohayato.com/"
              rel="me"
              aria-label="ブログ"
              className={styles.authorProfileIcon}
            >
              <Blog size="1.1em" />
            </a>
            <a
              href="https://dyno.design/"
              rel="me"
              aria-label="仕事"
              className={styles.authorProfileIcon}
            >
              <Work size="1.1em" />
            </a>
            <a
              href="https://www.youtube.com/@gh640-youtube"
              rel="me"
              aria-label="YouTube"
              className={styles.authorProfileIcon}
            >
              <YouTube size="1.1em" />
            </a>
          </p>
        </div>
      </div>
    </BaseWidget>
  )
}

export default AuthorWidget
